/* .video-responsive {
    overflow: hidden;
    padding-bottom: 30%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    margin-top: 40px;
    height: 80%;
    width: 40%;
    position: absolute;
  }
   */

.video-responsive iframe{
  width:'70%';
  height:'70%'
}

@media (max-width: 768px) {
  .video-responsive {
    overflow: hidden;
    padding-bottom: 30%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    margin-top: 40px;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

@media (max-width: 640px) {
  .video-responsive {
    overflow: hidden;
    padding-bottom: 30%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    margin-top: 40px;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .iframe{
    height: 1000;
    width: 1000;
  }
}

/* xs */
@media (max-width: 475px) {
  .video-responsive {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .video-responsive iframe {
    left: 0;
    margin-top: 40px;
    height: 100%;
    width: 100%;
    position: absolute;
    
  }

  .iframe{
    height: 100;
    width: 100;
  }
}