.button{
    height: 40px;
    width: 400px;
    border-radius: 20px;
    background-color: #000080;
    color: #ffffff;
}

@media (max-width: 640px) {
    .button{
        width: 300px;
    }
}

/* xs */
@media (max-width: 475px) {
    .button{
        width: 300px;
    }
}