*{
    margin: 0;
}
.Nether{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.Nether p{
    text-align: start;
}
.pictures{
    display: flex;
    flex-direction: row;
}

.pictures img{
    width: 300px;
    height: 300px;
    margin: 30px;
}
.Neatherland{
    width: 100%;
}

.sanjeev{
    display: flex;
    flex-direction: row;
}

.sanjeev img{
    width: 400px;
    height: 400px;
    margin: 30px;
}

.yas_exp p{
    font-size: 30px;
    color: white;
}

/*The desktop-first pre-defined media queries:*/
/* 2xl */
@media (max-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {
    .pictures{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
   
    .pictures img{
        width: 800px;
        height: 800px;
        margin: 30px;
        margin-left: 100px;
    }
}

/* lg */
@media (max-width: 1024px) {
    .pictures{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
   
    .pictures img{
        width: 600px;
        height: 600px;
        margin: 30px;
        margin-left: 100px;
    }
}

/* md */
@media (max-width: 768px) {
    .pictures{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .pictures img{
        width: 400px;
        height: 400px;
        margin: 30px;
        margin-left: 80px;
    }
}

/* sm */
@media (max-width: 640px) {
    .pictures{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .pictures img{
        width: 400px;
        height: 400px;
        margin: 30px;
        margin-left: 80px;
    }
    .sanjeev{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .sanjeev img{
        width: 90%;
        height: 90%;
    }
}

/* xs */
@media (max-width: 475px) {
    .pictures{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
   
    .pictures img{
        width: 60%;
        height: 60%;
    }
    .sanjeev{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .sanjeev img{
        width: 60%;
        height: 60%;
    }
}