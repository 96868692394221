.about{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: bold;
}

.Aim{
    color: #990000;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
}

.Aim h5{
    font-weight: bold;
    font-size: 22px;
    text-align: start;
    margin-top: 30px;
}


.Aim p{
    font-weight: bold;
    font-size: 20px;
    text-align: start;
}
.lapsandgrads{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    text-align: center;
}
.lapsandgrads img{
    width: 500px;
    height: 500px;
    margin: 30px;
}
.whoarewe{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
    color: #1c4587;
}
.stories{
    display: flex;
    flex-direction: row;
    margin-left: 5px;
}

.portfolio-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(450px,1fr));
    gap: 2rem;
    margin-left: 10px;
    margin-top: 10px;
}
.portfolio-grid img{
    width: 400px;
    height: 400px;
}
.Team{
    align-items: center;
    margin-top: 50px;
    text-align: center;
}
.CEO_DES{
    text-align: center;
}
.SConsultant_DES{
    text-align: center;
}
.COO_DES{
    text-align: center;
}
.AdHead_DES{
    text-align: center;
}
/*The desktop-first pre-defined media queries:*/
/* 2xl */
@media (max-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {}

/* lg */
@media (max-width: 1024px) {}

/* md */
@media (max-width: 768px) {
    .lapsandgrads{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .stories{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

}

/* sm */
@media (max-width: 640px) {
    .lapsandgrads{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .stories{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .lapsandgrads img{
        width: 400px;
        height: 400px;
        margin-left: 15%;
    }
}

/* xs */
@media (max-width: 475px) {
    .lapsandgrads{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .stories{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .lapsandgrads img{
        width: 300px;
        height: 300px;
        margin-left: 25px;
    }
}